import { useAuthStore } from '~/stores/auth';

/* eslint-disable consistent-return */
export default defineNuxtRouteMiddleware(async (to) => {
  if (process.server) return;

  const { tryLogin, getLoginUrl, LoginErrorStatus } = useAuthStore();

  const tryLoginResult = await tryLogin(to.query.redirectLoggedIn === 'true');

  // If the user is not logged in and is navigating to one of the dashboard pages (/mina-sidor/*)
  // we want to redirect them to the login page keeping the current dashboard path as the redirect path
  if (!tryLoginResult.success) {
    const localePath = useLocalePath();
    const dashboardPath = localePath({
      name: 'shop-dashboard',
    });
    const isDashboardPath = to.fullPath.startsWith(dashboardPath);
    if (isDashboardPath) {
      // If the user is logged in but is missing first/last name, we want to redirect them to the index page to let them set it,
      // otherwise they will be redirected to the login page again making it impossible for them to set the name
      if (tryLoginResult.errorStatus === LoginErrorStatus.MISSING_PROFILE) {
        return navigateTo(localePath({ name: 'shop' }));
      }

      // Redirect to login page with the current path as the redirect path
      const loginUrl = getLoginUrl({
        redirectPath: to.fullPath,
      });

      return navigateTo(loginUrl, { external: true });
    }

    return navigateTo(localePath({ name: 'shop' }));
  }

  try {
    localStorage.setItem('hasLoggedInBefore', 'hasLoggedInBefore');
  } catch {
    console.info('LocalStorage unavailable');
  }
});
/* eslint-enable consistent-return */
